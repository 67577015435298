<template>
    <div>
        <div class="middle-sidebar-bottom">
            <div class="middle-sidebar-left col-lg-12 col-md-12 col-sm-12">
                <div class="row col-lg-12 col-md-12 col-sm-12" v-if="dataExist">
                    <div class="col-lg-12">
                        <div class="card w-100 mb-4 bg-lightblue p-lg-5 p-4 border-0 rounded-lg d-block float-left">
                            <div class="row">
                                <div class="col-12">
                                    <span class="font-xsss fw-500 text-grey-500 d-inline-block float-right ml-0">{{
                                        announcements.created_at
                                        | formatDate }}</span>
                                    <h4 class="fw-700 display1-size font-xl mb-1">{{ announcements.title }}</h4>
                                    <hr>
                                </div>
                                <div class="col-12 bg-white ql-editor" v-html="announcements.message">
                                </div>
                            </div>
                        </div>
                        <div class="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 bg-grey">
                            <h2 class="fw-600 font-xs mb-3 mt-1 pl-1 text-current mb-4">{{ announcements.comment.length }}
                                Comments</h2>
                            <div v-for="(item, index) in announcements.comment" :key="index" class="message-item">
                                <div class="row message-user">
                                    <figure class="avatar">
                                        <img class=" rounded-circle"
                                            :src="item.user.photo ? item.user.photo : '/images/profile.png'"
                                            width="100px" alt="">
                                    </figure>
                                    <div class="col">
                                        <div class="row">
                                            <h5 class="font-xssss mt-2 ml-3 font-weight-bold">{{ item.user_id.name }}</h5>
                                            <div class="time font-xsssss mt-1 ml-3">{{ item.created_at | formatDate }}</div>
                                        </div>
                                        <div class="message-wrap shadow-none font-xsss fw-500 mt-0">{{ item.message }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-grey p-3 shadow-xss">
                            <div class="card bg-grey p-1 d-block border-0 d-block">
                                <form @submit.prevent="postComment()"
                                    class="d-flex flex-nowrap row form-group icon-right-input style1-input mb-0">
                                    <input type="text" v-model="message" placeholder="Start typing..."
                                        class="form-control rounded-xl bg-white border-0 font-xsss fw-500 pl-3">
                                    <button type="submit" class="border-0"><i
                                            class="feather-arrow-right-circle text-grey-700 font-lg mr-2"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                <div class="my-3" v-show="loadData">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            media : process.env.VUE_APP_URL_CLOUD,
            announcements: {},
            paramsId: this.$route.params.idAnnouncement,
            dataExist: false,
            dataNotExist: false,
            loadData: true,
            message: ''
        }
    },
    created() {
        this.getAnnouncements()
    },
    methods: {
        async getAnnouncements(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/mycourse/announcement?id=${this.paramsId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data.length == 0) {
                    this.dataNotExist = true
                } else {
                    this.dataExist = true
                    this.announcements = res.data
                }
            }).catch((err) => {
                this.loadData = false
                if (err.response.status) {
                    this.dataNotExist = true
                }
            })
        },
        async postComment() {
            var data = {
                announcement_id : this.paramsId,
                message : this.message,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/core/mycourse/announcement/comment`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.$swal({
                    toast: true,
                    title: 'Announcement Comment !',
                    text: 'Success send comment!',
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.message= '';
                this.getAnnouncements()
            }).catch((err) => {
                if (err.response.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Announcement Comment !',
                        text: 'Field is Empty !',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
    }
}
</script>

<style>
.ql-editor {
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: 0;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word
}

.ql-editor>* {
    cursor: text
}

.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em
}

.ql-editor ol>li,
.ql-editor ul>li {
    list-style-type: none
}

.ql-editor ul>li::before {
    content: '\2022'
}

.ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em
}

.ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.ql-editor ol li:before {
    content: counter(list-0, decimal) '. '
}

.ql-editor ol li.ql-indent-1 {
    counter-increment: list-1
}

.ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-2 {
    counter-increment: list-2
}

.ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. '
}

.ql-editor ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-3 {
    counter-increment: list-3
}

.ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. '
}

.ql-editor ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-4 {
    counter-increment: list-4
}

.ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-5 {
    counter-increment: list-5
}

.ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. '
}

.ql-editor ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-6 {
    counter-increment: list-6
}

.ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. '
}

.ql-editor ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-7 {
    counter-increment: list-7
}

.ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-7 {
    counter-reset: list-8 list-9
}

.ql-editor ol li.ql-indent-8 {
    counter-increment: list-8
}

.ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. '
}

.ql-editor ol li.ql-indent-8 {
    counter-reset: list-9
}

.ql-editor ol li.ql-indent-9 {
    counter-increment: list-9
}

.ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. '
}

</style>
